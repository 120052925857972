import './App.css';
import { IFrameLoader } from './components/IFrameLoader';

function App() {
  return (
    <div className="App">
        <IFrameLoader></IFrameLoader>
    </div>
  );
}

export default App;
