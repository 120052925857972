import { Component } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import './IFrameLoader.css'
import axios from "axios";

function fetchGuestTokenFromBackend(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const backendUrl = `${window.location.protocol}//${window.location.hostname}:8000/fetchGuestToken`;
        console.log("====> Calling token main!",backendUrl)
        axios.post(backendUrl)
            .then((response) => {
                const token = JSON.parse(response.data).token; // Attempt to extract the token
                if (token) {
                    console.log("Token found:", token); // Log the found token for confirmation
                    resolve(token);
                } else {
                    console.error("Token not found in response");
                    reject("Token not found");
                }
            })
            .catch((error) => {
                console.error("Error fetching token:", error);
                reject(error);
            });
    });
}

export class IFrameLoader extends Component {
    state = {
        isLoaded: false
    }

    componentDidMount() {
        this.setState({ isLoaded: true });
        console.log("Requesting dashboard embed with token")
        embedDashboard({
            id: "ca87a5d8-bf71-42c9-9b14-41fd45296b9c", // given by the Superset embedding UI
            supersetDomain: "https://superset.dev.tocos.ch",
            mountPoint: document.getElementById("superset-container")!, // any html element that can contain an iframe
            dashboardUiConfig: { // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
                hideTitle: true,
                hideChartControls: true,
                filters: {
                    expanded: false,
                }
            },
            fetchGuestToken: () => fetchGuestTokenFromBackend(),
        });
    }

    render() {
        return <div id="superset-container" className="IFrameLoader"></div>
    }
}
